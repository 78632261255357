import React from "react";
import { PagSliderRightIcon} from "../../../../assets/images";

//Import Assets
import "./newsCard.scss";
import {generateImageMediaUrl} from "../../../../utils/generateMediaUrl";
import moment from "moment";
import {Link} from "react-router-dom";
import {getUrlWithLocal} from "../../../../utils/getUrlWithLocal";

function NewsCard(props){
    const {item,link,staticTexts,hrefs} = props

    return <div className={'news-block'}>
        <div className={'news-block-img'}>
            <img src={generateImageMediaUrl(item?.mediaMain?.path)}/>
        </div>
        <div className={'news-block-info'}>
            <div className={'time-wrapper'}>
                {
                    item?.date && <div className={'news-date'}>
                        {moment(item?.date).format("DD.MM.YYYY")}
                    </div>
                }
                {
                    item?.time && <div className={'news-date'}>
                        {moment(item?.time).format("HH:mm")}
                    </div>
                }
            </div>
            <div className={'news-title-wrapper'}>
                <div className={'news-block-title'}>
                {item?.title}
                </div>
            </div>
            <div className={'news-block-description'} dangerouslySetInnerHTML={{__html: item?.description}}/>
            {/*{*/}
            {/*    link ? <Link to={getUrlWithLocal(`${link}/${item?.slug}`)} className={'news-block-link'}>*/}
            {/*        <span>{staticTexts?.see_more} <PagSliderRightIcon/></span>*/}
            {/*    </Link> : ''*/}
            {/*}*/}
            {
                item?.link ? <a href={item?.link} className={'news-block-href'} target={'_blank'}>
                    <span>{staticTexts?.see_more} <PagSliderRightIcon/></span>
                </a> : <Link to={getUrlWithLocal(`${link}/${item?.slug}`)} className={'news-block-link'}>
                    <span>{staticTexts?.see_more} <PagSliderRightIcon/></span>
                </Link>
            }
        </div>
    </div>
}
export default NewsCard