// import packages
import React from "react";
import {connect} from "react-redux";
import moment from "moment";

//Import assets
import '../assets/styles/containers/blog-details.scss'

//Import components

//Import utils
import {GetSingleBlog} from "../redux/actions";
import {getPropsFromState} from "../redux/mapStateToProps";
import {generateImageMediaUrl} from "../utils/generateMediaUrl";
import {useParams} from "react-router-dom";
import {useEffect} from "react";
import {
    BlogBgIcon,
    BlogResponsBgIcon, PagSliderRightIcon,
} from "../assets/images";
import Footer from "../components/Footer";

function BlogDetails(props){
    const {singleBlog,staticTexts} = props
    const params = useParams()

    useEffect(() => {
        props.GetSingleBlog(params.slug)
    },[params.slug])

    return <div className={'blog-details-wrapper'}>
        <div className={'blogBg'}>
            <BlogBgIcon/>
        </div>
        <div className={'responsiveBg'}>
            <BlogResponsBgIcon/>
        </div>
        <div className={'details-main-block'}>
            <div className={'left-block'}>
                <div className={'block-title'}>
                    {singleBlog?.title}
                </div>
                <div className={'time-wrapper'}>
                    {
                        singleBlog?.date && <div className={'news-date'}>
                            {moment(singleBlog?.date).format("DD.MM.YYYY")}
                        </div>
                    }
                    {
                        singleBlog?.time && <div className={'news-date'}>
                            {moment(singleBlog?.time).format("HH:mm")}
                        </div>
                    }
                </div>
                {/*<div className={'block-description'}>*/}
                {/*    {singleBlog?.description}*/}
                {/*</div>*/}
                {/*<div className={'block-date'}>*/}
                {/*    <span>{staticTexts?.see_more}</span> <PagSliderRightIcon/>*/}
                {/*</div>*/}
                <div className={'block-description'}>
                    <div dangerouslySetInnerHTML={{__html: singleBlog?.description}}/>
                </div>
                <div className={'details-info-block'}>
                    <div className={'text'} dangerouslySetInnerHTML={{__html: singleBlog?.details}}/>
                </div>
            </div>
            <div className={'right-block'}>
                <div className={'img-block'}>
                    <img src={generateImageMediaUrl(singleBlog?.mediaMain?.path)}/>
                </div>
            </div>
        </div>
        {/*<div className={'details-info-block'}>*/}
        {/*    <div className={'text'} dangerouslySetInnerHTML={{__html: singleBlog?.details}}/>*/}
        {/*</div>*/}
        {singleBlog?.summary ? <div className={'summary-wrapper'}>
                <div className={'summary'}>
                    <div className={'summary-title'}>
                        {staticTexts?.blog_page_summery}
                    </div>
                    <div className={'summary-description'} dangerouslySetInnerHTML={{__html:singleBlog?.summary}}/>
                </div>
            </div> : ''
        }
        <Footer/>
    </div>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'selectedLanguage',
        'singleBlog',
        'blogs',
        'staticTexts',
    ])
};

const mapDispatchToProps = {
    GetSingleBlog
};

export default connect(mapStateToProps, mapDispatchToProps)(BlogDetails);